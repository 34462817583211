import React from "react"
import ArticleItem from "../../components/ArticleItem"

import "./style.scss"

const RecommendedReadsArticles = ({ recommendedReadsArticles }) => {
  if (!recommendedReadsArticles.length) {
    return null;
  }

  return (
    <div className="recommended-reads">
      <div className="grid-section">
        <div className="recommended-reads-block-title">
          <h3 className="text h3">Recommended Reads</h3>
        </div>

        <div className="articles-list">
          {recommendedReadsArticles.map(
            ({ mainImage, tags, title, url }, index) => (
              <ArticleItem
                image={mainImage}
                tags={tags}
                title={title}
                url={`/blog/${url}`}
                key={index}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default RecommendedReadsArticles
