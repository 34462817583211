import React, {useEffect} from "react"

import "../styles/templates/article.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RecommendedReadsArticles from "../components/RecommendedReadsArticles"
import FacebookIcon from "../images/assets/Socials/Facebook.svg"
import InstagramIcon from "../images/assets/Socials/Instagram.svg"
import { GatsbyImage } from "gatsby-plugin-image"

const Article = ({ pageContext }) => {
  const { blog, otherArticles } = pageContext
  const { mainImage, title, content, tags, id, publishedAt, excerpt } = blog

  const recommendedReads = []
  for (let i = 0; i < otherArticles.length; i++) {
    if (recommendedReads.length === 3) break

    const currentNode = otherArticles[i].node
    const isUnique = currentNode.id !== id
    if(tags?.length > 0){
      if (currentNode.tags?.includes(tags[0]) && isUnique) {
        recommendedReads.push(currentNode)
      }
    }
  }

  useEffect(() => {
    if (typeof window !== undefined) {
      window.dataLayer.push({
        event: "blog_view",
        blog_type: tags?.join('-'),
        blog_title: title,
        blog_published_at: publishedAt
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <SEO title={title} description={excerpt} />
      <div className="article-content">
        <div className="heading-container">
          <div className="grid-section">
            <div className="links-block">
              <div className="tags-container">
                {tags?.map((tag, index) => (
                  <p className="tagname text h8" key={index}>
                    {tag}
                  </p>
                ))}
              </div>
              <div className="socials">
                <a href="https://www.facebook.com/HubbleContacts" target="_blank">
                  <FacebookIcon />
                </a>
                <a href="https://www.instagram.com/hubblecontacts/?hl=en" target="_blank">
                  <InstagramIcon />
                </a>
              </div>
            </div>
            <div className="heading-block">
              <h1 className="heading text h2 bulky-wide">{title}</h1>
              <div className="image-container">
                <GatsbyImage image={mainImage.gatsbyImageData} alt="" />
              </div>
            </div>
          </div>
        </div>
          <div className="content-wrapper">
            <div className="grid-section">
              <div className="article-wrapper">
                <div className="article-container">
                  <div className="socials-wrapper">
                    <div className="sticky-socials">
                      <a href="https://www.facebook.com/HubbleContacts" target="_blank">
                        <FacebookIcon />
                      </a>
                      <a href="https://www.instagram.com/hubblecontacts/?hl=en" target="_blank">
                        <InstagramIcon />
                      </a>
                    </div>
                  </div>
                  <div
                    className="article-content"
                    dangerouslySetInnerHTML={{ __html: content?.content }}
                  />
                </div>
              </div>
            </div>
        </div>

        <RecommendedReadsArticles recommendedReadsArticles={recommendedReads}/>
      </div>
    </Layout>
  )
}

export default Article
